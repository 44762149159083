// Alerts
export const SET_ALERT = "SET_ALERT"
export const REMOVE_ALERT = "REMOVE_ALERT"

// Auth
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_FAIL = "REGISTER_FAIL"
export const USER_LOADED = "USER_LOADED"
export const AUTH_ERROR = "AUTH_ERROR"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const LOGOUT = "LOGOUT"

// Users
export const USER_ERROR = "USER_ERROR"
export const GET_USER = "GET_USER"

// Quiz
export const SET_AGREEMENT = "SET_AGREEMENT"
export const SET_COOKIES = "SET_COOKIES"
export const SET_LOCATION = "SET_LOCATION"
export const SET_QUESTION = "SET_QUESTION"
export const GET_QUESTION_DATA = "GET_QUESTION_DATA"
export const SET_QUIZ_TALLY = "SET_QUIZ_TALLY"
export const SET_MINDSET = "SET_MINDSET"
export const SET_COMPLETED = "SET_COMPLETED"
export const RESET_QUIZ = "RESET_QUIZ"

// Mindset
export const LOAD_MINDSET_SUCCESS = "LOAD_MINDSET_SUCCESS"
export const LOAD_MINDSET_ERROR = "LOAD_MINDSET_ERROR"
export const LOAD_MINDSETS_SUCCESS = "LOAD_MINDSETS_SUCCESS"
export const LOAD_MINDSETS_ERROR = "LOAD_MINDSETS_ERROR"

// Resources
export const FETCH_FEATURE_RESOURCE = "FETCH_FEATURE_RESOURCE"
export const FETCH_RESOURCE_CATEGORIES = "FETCH_RESOURCE_CATEGORIES"
export const FETCH_RESOURCE_FOR_STATE = "FETCH_RESOURCE_FOR_STATE"
export const RESET_FEATURE_RESOURCE = "RESET_FEATURE_RESOURCE"
