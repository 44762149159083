const details = [
  "who",
  "where",
  "when",
  "time",
  "cost",
  "award",
  "covered_expenses",
  "likelihood",
  "safety",
  "story",
  "attorney",
]

export default { details }
